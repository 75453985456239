import { createGlobalStyle } from 'styled-components'

const GlobalStyle = createGlobalStyle`
  body {
    background-color: ${({ theme }) => theme.colors.background};

    img {
      height: auto;
      max-width: 100%;
    }
	
  }
  .videoBack {
    z-index: -1;
    position:fixed;
    height:100%;
    object-fit: fill;
    width:100%;
  }

`

export default GlobalStyle
