import { MenuEntry } from '@pancakeswap-libs/uikit'

const config: MenuEntry[] = [
  {
    label: 'Home',
    icon: 'HomeIcon',
    href: 'https://potluckprotocol.com/'
  },
  {
    label: 'Trade',
    icon: 'TradeIcon',
    initialOpenState: true,
    items: [
      {
        label: 'Exchange',
        href: '/swap'
      },
      {
        label: 'Liquidity',
        href: '/pool'
      }
    ]
  },
  {
    label: 'Farms',
    icon: 'FarmIcon',
    href: 'https://potluckprotocol.com/farms'
  },
  {
    label: 'Pools',
    icon: 'PoolIcon',
    href: 'https://potluckprotocol.com/nests'
  },
  // {
  //   label: 'Lottery',
  //   icon: 'TicketIcon',
  //   href: 'https://potluckprotocol.com/lottery'
  // },
  {
    label: 'Private Sale',
    icon: 'NftIcon',
    href: 'https://presale.potluckprotocol.com/',
  },
  {
    label: 'Info',
    icon: 'InfoIcon',
    items: [
      {
        label: 'Twitter',
        href: 'https://twitter.com/PotluckProtocol',
      },
    ],
  },
  {
    label: 'More',
    icon: 'MoreIcon',
    items: [
      {
        label: 'LitePaper',
        href: 'https://pdfhost.io/v/ztHkaubHk_PTLK_LITESHEETpdf.pdf',
      },
      // {
      //   label: 'Docs',
      //   href: 'https://goosedefi.gitbook.io/goose-finance/',
      // },
      {
        label: 'Medium',
        href: 'https://medium.com/@potluckprotocol',
      },
    ],
  },
  {
    label: 'Slasher',
    icon: 'HamburgerIcon',
    href: 'https://slasherbsc.com/',
  },
  // {
  //   label: 'Purchase',
  //   icon: 'AuditIcon',
  //   href: 'https://slasher.potluckprotocol.com',
  // },
  {
    label: 'Sonar (coming soon)',
    icon: 'HamburgerCloseIcon',
    href: 'https://potluckprotocol.com/#/',
  },
  // {
  //   label: 'Audit by Hacken',
  //   icon: 'AuditIcon',
  //   href: 'https://www.goosedefi.com/files/hackenAudit.pdf',
  // },
  // {
  //   label: 'Audit by CertiK',
  //   icon: 'AuditIcon',
  //   href: 'https://certik.org/projects/goose-finance',
  // },
]

export default config
